.rents {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}

.card {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

hr {
  border: 1px solid lightgray;
}

.bold {
  font-weight: 500;
}

.icon {
  color: lightgray;
}

.icon:hover {
  color: #1677ff;
}

.title {
  cursor: pointer;
}

.title:hover {
  color: #1677ff;
}

.btn {
  cursor: pointer;
  color: #1677ff;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px; /* Отступ между карточками */
  margin: 0 auto; /* Центрирование контейнера */
}

.text {
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .ant-layout-content {
    padding: 5px !important;
    margin: 10px !important;
  }
  .ant-drawer-body {
    padding: 10px !important;
  }
}

.wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 10px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.logo_block {
  text-align: center;
  padding: 30px;
  color: grey;
}

.logo {
  width: 60px;
  border-radius: 3px;
}

.content {
  white-space: pre-wrap;
}

.task {
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.message_area {
  padding: 20;
  background-color: rgb(237, 237, 237);
}

.gray {
  color: gray;
}

.error {
  color: red;
}

.lightgray {
  color: lightgray;
}

.ant-descriptions-header {
  margin-bottom: 5px !important;
}

.ant-descriptions-view th, td {
  padding: 8px 12px !important;
}

.file {
  width: 150px;
  height: 150px;
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
}

.choiced {
  border-color: #1677ff;
}